import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Locale from 'date-fns/locale/en-GB'
import axios from 'axios'
import { toast } from 'react-toastify'
import { saveAs } from 'file-saver'
import { capitalizeFirstLetter } from '../../../../utils'
import { ClearAll, CloudDownload } from '@material-ui/icons'

const Manage_Tasks_Card_Toolbar = ({ params, isActive, setParams, taskUser, title }) => {
  const downloadCSV = () => {
    const downloadParams = taskUser ? { ...params, task_user: taskUser, is_active: isActive } : params
    axios
      .get(`task/download_csv`, {
        responseType: 'blob',
        params: downloadParams
      })
      .then((response) => {
        if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          saveAs(response.data, taskUser ? 'My_Tasks.csv' : 'Tasks.csv')
        } else {
          toast.error('Something went wrong!')
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log(err)
      })
  }
  return (
    <Box sx={{ fieldset: { border: '1px solid' }, mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant='h5'>{title}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TextField
          label='Kërko'
          size='small'
          sx={{ marginRight: '10px', width: '150px' }}
          onChange={(e) => setParams((prev) => ({ ...prev, page: 0, name: e.target.value }))}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
          <TimePicker
            ampm={false}
            value={params?.due_time}
            onChange={(e) => setParams((prev) => ({ ...prev, page: 0, due_time: e }))}
            renderInput={(params) => (
              <TextField {...params} label='Due Time' placeholder='Hours:Minutes' variant='outlined' size='small' sx={{ width: '120px' }} />
            )}
            componentsProps={{
              actionBar: {
                actions: ['clear']
              }
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
          <DatePicker
            label='Due Date'
            inputFormat='dd/MM/yyyy'
            value={params?.due_date}
            onChange={(e) => setParams((prev) => ({ ...prev, page: 0, due_date: e }))}
            renderInput={(params) => <TextField {...params} sx={{ marginX: '10px', width: '150px' }} size='small' />}
            componentsProps={{
              actionBar: {
                actions: ['clear']
              }
            }}
          />
        </LocalizationProvider>
        <FormControl sx={{ width: '180px', textAlign: 'left' }}>
          <InputLabel size='small' id='task_type_select_label'>
            {!isActive ? '	E përsëritshme' : 'Tipi i Taskës'}
          </InputLabel>
          <Select
            size='small'
            multiple
            labelId='task_type_select_label'
            value={params?.task_type ?? []}
            onChange={(e) => setParams((prev) => ({ ...prev, page: 0, task_type: e.target.value }))}
            input={<OutlinedInput label='Task Type' />}
            renderValue={(selected) => selected.map((item) => capitalizeFirstLetter(item)).join(', ')}
            disabled={!isActive}
          >
            {['single', 'repetitive'].map((item) => (
              <MenuItem key={item} value={item}>
                <Checkbox checked={(params?.task_type ?? []).indexOf(item) > -1} />
                <ListItemText primary={capitalizeFirstLetter(item)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: '250px', textAlign: 'left', marginX: '10px' }}>
          <InputLabel size='small' id='assignment_type_select_label'>
            Tipi i Delegimit
          </InputLabel>
          <Select
            size='small'
            multiple
            labelId='assignment_type_select_label'
            value={params?.assignment_type ?? []}
            onChange={(e) => setParams((prev) => ({ ...prev, page: 0, assignment_type: e.target.value }))}
            input={<OutlinedInput label='Assignment Type' />}
            renderValue={(selected) => selected.map((item) => capitalizeFirstLetter(item)).join(', ')}
          >
            {['all_users', 'per_user'].map((item) => (
              <MenuItem key={item} value={item}>
                <Checkbox checked={(params?.assignment_type ?? []).indexOf(item) > -1} />
                <ListItemText primary={capitalizeFirstLetter(item)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button startIcon={<CloudDownload />} size='small' variant='contained' sx={{ marginRight: '10px' }} onClick={() => downloadCSV()}>
          CSV
        </Button>
        <Button
          variant='outlined'
          size='small'
          startIcon={<ClearAll />}
          onClick={() => setParams((prev) => ({ ...prev, due_time: null, due_date: null, task_type: null, assignment_type: null }))}
        >
          Clear
        </Button>
      </Box>
    </Box>
  )
}

export default Manage_Tasks_Card_Toolbar
