import Header from './Header'
import Sidebar from './Sidebar'

const Base = (props) => {
  const year = new Date().getFullYear()
  return (
    <div className='wrapper'>
      <Header />

      <Sidebar />

      <section className='section-container'>{props.children}</section>

      <footer className='footer-container'>
        <span>&copy; {year} - Speeex</span>
      </footer>
    </div>
  )
}

export default Base
