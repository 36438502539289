import { Box, CircularProgress, Typography } from '@mui/material'

import { useState } from 'react'
import Complete_Task_Modal from './../Complete_Task_Modal/Complete_Task_Modal'
import Task_Report_Modal from './../Task_Report_Modal/Task_Report_Modal'
import My_Today_Tasks_Timeline from './My_Today_Tasks_Timeline'
import { useQuery } from '@tanstack/react-query'
import { myTodayTasks } from '../../API/endpoints'
const My_Today_Tasks_Tab = () => {
  const [isCompletedTask, setIsCompletedTask] = useState(false)
  const [taskId, setTaskId] = useState(null)

  const todayTask = useQuery({
    queryKey: ['myTodayTask'],
    queryFn: myTodayTasks,
    staleTime: 50000
  })

  return (
    <Box mt={2}>
      {/* {todayTask.data.length >= 1 && (
        <Typography variant='h6' fontWeight='bold'>
          My Tasks
        </Typography>
      )} */}
      {todayTask.isLoading ? (
        <CircularProgress />
      ) : todayTask?.data?.length > 0 ? (
        <My_Today_Tasks_Timeline tasks={todayTask.data} setTaskId={setTaskId} setIsCompletedTask={setIsCompletedTask} />
      ) : (
        <Typography>Nuk ka Taska për ditën e sotme!</Typography>
      )}

      {isCompletedTask && (
        <Task_Report_Modal
          task_id={taskId}
          onCloseModal={() => {
            setTaskId(null)
            setIsCompletedTask(false)
          }}
        />
      )}
      {taskId && !isCompletedTask && <Complete_Task_Modal task_id={taskId} onCloseModal={() => setTaskId(null)} />}
    </Box>
  )
}

export default My_Today_Tasks_Tab