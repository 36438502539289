import { AccessTime, ArrowBack, BusinessOutlined, CalendarToday, PersonOutlineOutlined, Repeat, RepeatOne } from '@material-ui/icons'
import { Box, Button, Card, Dialog, DialogActions, DialogTitle, Divider, Grid, Typography, Tooltip } from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CustomChip, RenderProfiles, capitalizeFirstLetter, cardStyling } from '../utils'

const Task_Details = () => {
  const { id } = useParams()
  const [taskDetails, setTaskDetails] = useState([])
  const [assignedUsers, setAssignedUsers] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [isActive, setIsActive] = useState()
  const [repititionDays, setRepititionDays] = useState([])

  useEffect(() => {
    axios
      .get(`task/${id}`)
      .then((res) => {
        if (res.data.success) {
          const data = res.data.data
          setIsActive(data.is_active)
          setRepititionDays(data.repetition)
          setTaskDetails([
            {
              title: 'Name',
              value: data?.name
            },
            {
              title: 'Description',
              value: data?.description
            },
            {
              title: 'Tipi',
              value: data?.task_type == 'single' ? 'Njëshe' : 'E përsëritshme',
              icon: data?.task_type == 'single' ? <RepeatOne fontSize='large' /> : <Repeat fontSize='large' />
            },
            {
              title: 'Tipi i Delegimit',
              value: capitalizeFirstLetter(data?.assignment_type ?? ''),
              icon: data?.assignment_type == 'per_user' ? <PersonOutlineOutlined fontSize='large' /> : <BusinessOutlined fontSize='large' />
            },

            {
              title: 'Afati Kohor',
              value: `${data?.due_time?.hours}:${data?.due_time?.minutes}`,
              icon: <AccessTime fontSize='large' />
            },

            {
              title: 'Data e Afatit',
              value: data.due_date ? moment(data.due_date).format('DD/MM/YYYY') : '-',
              icon: <CalendarToday fontSize='large' />
            }
          ])
          setAssignedUsers(data.assigned_to)
        } else {
          setTaskDetails([])
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }, [id])

  const handleDeactivateTask = async (is_active) => {
    setOpenModal(false)
    axios
      .patch(`task/deactivate/${id}`, { is_active: is_active })
      .then((res) => {
        if (res.data.success) {
          toast.success('Task activated status changed successfully!')
          history.goBack()
        } else {
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }

  const history = useHistory()
  return (
    <Box sx={{ padding: '20px' }}>
      <Button size='small' variant='outlined' sx={{ marginBottom: '20px' }} startIcon={<ArrowBack />} onClick={() => history.goBack()}>
        Back
      </Button>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box width='100%'>
          <Dialog open={openModal}>
            <DialogTitle>Are you sure you want to deactivate this task ?</DialogTitle>
            <DialogActions>
              <Button variant='contained' color='success' onClick={() => setOpenModal(false)}>
                No
              </Button>
              <Button variant='contained' color='error' onClick={() => handleDeactivateTask(!isActive)}>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          {isActive ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                variant='body1'
                sx={{
                  padding: '5px 15px',
                  borderRadius: '12px',
                  border: `1px solid ${isActive ? 'green' : 'red'}`,
                  background: ` ${isActive ? 'green' : 'red'}`,
                  color: '#fff',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  letterSpacing: '1px'
                }}
              >
                Aktive
              </Typography>
              <Button variant='contained' color='error' onClick={() => setOpenModal(true)}>
                Deaktivizo
              </Button>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                variant='body1'
                sx={{
                  padding: '5px 15px',
                  borderRadius: '12px',
                  border: `1px solid ${isActive ? 'green' : 'red'}`,
                  background: ` ${isActive ? 'green' : 'red'}`,
                  color: '#fff',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  letterSpacing: '1px'
                }}
              >
                Jo Aktive
              </Typography>
              <Button variant='contained' color='success' onClick={() => setOpenModal(true)}>
                Aktivizo
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box mt={5} mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Card sx={cardStyling}>
              <Box display='flex' justifyContent='space-between' alignItems='center' pt={2} px={2}>
                <Typography variant='body1' fontWeight='bold' textTransform='capitalize'>
                  {taskDetails[0]?.value}
                </Typography>
              </Box>
              <Box p={2}>
                <Box mb={2} lineHeight={1}>
                  <Tooltip title={taskDetails[1]?.value} placement='bottom'>
                    <Typography
                      color='dark'
                      fontStyle={'italic'}
                      fontWeight='light'
                      title={taskDetails[1]?.value}
                      sx={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        lineClamp: '7',
                        WebkitLineClamp: '7'
                      }}
                    >
                      {taskDetails[1]?.value}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Grid container spacing={2}>
              {taskDetails.slice(2).map((items) => (
                <Grid key={items._id} item xs={6}>
                  <Card sx={cardStyling} className='small'>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        gap: '20px'
                      }}
                    >
                      <Box>
                        <Typography variant='body1' fontWeight='bold' mb={1}>
                          {items.title}:
                        </Typography>
                        <Typography variant='body1' textTransform='capitalize'>
                          {items.value}
                        </Typography>
                      </Box>
                      {items?.icon}
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card sx={cardStyling}>
              <Typography variant='body1' fontWeight='bold'>
                Detajet e Delegimit:
              </Typography>
              {repititionDays.length > 0 && (
                <Box display='flex' sx={{ gap: '20px', flexWrap: 'wrap' }} py={1} pr={2}>
                  <CustomChip activeDay={repititionDays} />
                </Box>
              )}
            </Card>
          </Grid>
          <Grid item xs={12} md={12} xl={12} sx={{ display: 'flex' }}>
            <Card sx={{ height: '100%', width: '100%', border: 0, boxShadow: 0 }}>
              <Box pt={2} px={2}>
                <Typography variant='body1' fontWeight='bold'>
                  Userat e Deleguar
                </Typography>
              </Box>
              <Box p={2}>
                <Box
                  component='ul'
                  p={2}
                  m={0}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px 30px',
                    '> li': {
                      minWidth: '150px'
                    }
                  }}
                >
                  {console.log('assignedUsers', assignedUsers)}
                  {assignedUsers && <RenderProfiles assignedUsers={assignedUsers} />}
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Task_Details
