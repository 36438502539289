import React, { useState } from 'react'
import TaskCard from '../../../Task_Report_Card/Card'
import { useQuery } from '@tanstack/react-query'
import { tasks } from '../../../../API/endpoints'
import Manage_Tasks_Card_Toolbar from './Manage_Tasks_Card_Toolbar'
import CardPaginate from '../../../Task_Report_Card/Card_Paginate'
import { CircularProgress } from '@mui/material'

const InActiveCard = () => {
  const [params, setParams] = useState({
    limit: 10,
    page: 0,
    name: null,
    due_time: null,
    due_date: null,
    task_type: ['repetitive'],
    assignment_type: null,
    is_active: false
  })
  const inActiveTasks = useQuery({
    queryKey: [
      'inactiveTasks',
      params.limit,
      params.page,
      params.name,
      params.due_date,
      params.due_time,
      params.task_type,
      params.assignment_type,
      params.is_active
    ],
    queryFn: () => tasks(params),
    staleTime: 50000
  })
  if (inActiveTasks.isError) return <pre>{JSON.stringify(inActiveTasks.error)}</pre>

  return (
    <>
      <Manage_Tasks_Card_Toolbar params={params} isActive={false} setParams={setParams} title='Taskat jo Aktive' />
      {inActiveTasks.isLoading ? <CircularProgress /> : <TaskCard title='INactiveCard' tasksReportData={inActiveTasks} dep={true} />}
      <CardPaginate params={params} setParams={setParams} total={inActiveTasks?.data?.total} />
    </>
  )
}

export default InActiveCard
