import { CloudDownload } from '@material-ui/icons'
import { Box, Grid, Button, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import axios from 'axios'
import Locale from 'date-fns/locale/en-GB'
import { saveAs } from 'file-saver'
import { toast } from 'react-toastify'

const Tasks_Report_Card_Toolbar = ({ params, setParams, user = false, title }) => {
  const downloadCSV = () => {
    axios
      .get(user ? 'taskReport/users/download' : 'taskReport/tasks/download', {
        responseType: 'blob',
        params: params
      })
      .then((response) => {
        if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          saveAs(response.data, `Tasks_Reports.csv`)
        } else {
          toast.error('Something went wrong!')
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log(err)
      })
  }
  return (
    <Grid container sx={{ display: 'flex', justifyContent: 'space-between', pb: 2, mb: 2 }}>
      <Typography variant='h5'>{title}</Typography>
      <Box sx={{ fieldset: { border: '1px solid' }, display: 'flex', justifyContent: 'end' }}>
        <TextField
          label='Kërko...'
          size='small'
          sx={{ marginRight: '10px', width: '250px' }}
          onChange={(e) => setParams((prev) => ({ ...prev, page: 0, name: e.target.value }))}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
          <DatePicker
            label='Date'
            inputFormat='dd/MM/yyyy'
            value={params?.date}
            onChange={(e) => setParams((prev) => ({ ...prev, page: 0, date: e }))}
            renderInput={(params) => <TextField {...params} sx={{ marginX: '10px', width: '200px' }} size='small' />}
            componentsProps={{
              actionBar: {
                actions: ['clear']
              }
            }}
          />
        </LocalizationProvider>
        <Button startIcon={<CloudDownload />} size='small' variant='contained' onClick={() => downloadCSV()}>
          CSV
        </Button>
      </Box>
    </Grid>
  )
}

export default Tasks_Report_Card_Toolbar
