import { TablePagination } from '@mui/material'

const CardPaginate = ({ params, setParams, total }) => {
  const handleChangePage = (event, newPage) => {
    setParams((prev) => ({ ...prev, page: newPage }))
  }
  const handleChangeRowsPerPage = (event) => {
    setParams((prev) => ({ ...prev, page: 0, limit: parseInt(event.target.value, 10) }))
  }

  return (
    <TablePagination
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '30px',
        '.MuiTablePagination-selectLabel': {
          marginTop: '10px'
        },
        '.MuiTablePagination-displayedRows': {
          marginTop: '10px'
        }
      }}
      component='div'
      count={total}
      page={params.page}
      onPageChange={handleChangePage}
      rowsPerPage={params.limit}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}

export default CardPaginate
