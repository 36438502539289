import TaskReportCard from './components/Task_Report_Card/Task_report_card'
import UserReportCard from './components/User_Report_Card/User_Report_Card'

const Tasks_Report_Tab = () => {
  return (
    <>
      <TaskReportCard />
      <UserReportCard />
    </>
  )
}

export default Tasks_Report_Tab
