import { Box, Tabs, Tab } from '@mui/material'
import { useState } from 'react'
import My_Today_Tasks_Tab from '../components/My_Today_Tasks_Tab/My_Today_Tasks_Tab'
import UserDetails from './Details/User'

const My_Tasks = () => {
  const [tabIndex, setTabIndex] = useState(0)
  const setTabIndexOnQuery = (event, index) => {
    setTabIndex(index)
  }

  return (
    <Box sx={{ padding: '20px', height: '100vh' }}>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs value={tabIndex} onChange={setTabIndexOnQuery} centered fullWidth>
          <Tab label='Taskat e sotit' />
          <div style={{ width: '500px' }} />
          <Tab label='Raporti i Taskave' />
        </Tabs>
      </Box>
      {tabIndex === 0 && <My_Today_Tasks_Tab />}
      {tabIndex === 2 && <UserDetails />}
    </Box>
  )
}

export default My_Tasks
