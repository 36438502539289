import { CircularProgress, Box, Card } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { taskReport } from '../../../../API/endpoints'
import TaskCard from '../../../Task_Report_Card/Card'
import CardPaginate from '../../../Task_Report_Card/Card_Paginate'
import Tasks_Report_Card_Toolbar from '../Toolbar/Toolbar_Card'
import { mainBoxStyles } from '../../../Manage_Tasks_Tab/components/utils'

const TaskReportCard = () => {
  const [params, setParams] = useState({
    limit: 10,
    page: 0,
    name: null,
    date: new Date(),
    type: null
  })

  const tasksReportData = useQuery({
    queryKey: ['taskReport', params.limit, params.page, params.name, params.date, params.type],
    queryFn: () => taskReport(params),
    staleTime: 50000
  })

  if (tasksReportData.isError) return <pre>{JSON.stringify(tasksReportData.error)}</pre>

  return (
    <Card sx={mainBoxStyles}>
      <Tasks_Report_Card_Toolbar params={params} setParams={setParams} title='Raporti i Taskave' />

      {tasksReportData.isLoading ? (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TaskCard tasksReportData={tasksReportData} progressBar={true} completed={true} dep={false} report={true} date={params.date} />
      )}
      <CardPaginate params={params} setParams={setParams} total={tasksReportData?.data?.total} />
    </Card>
  )
}

export default TaskReportCard
