import React, { useState, useEffect } from 'react'
import MUIDataTable from 'mui-datatables'
import axios from 'axios'
import Loader from '../../Layout/Loader'
import { Badge } from 'reactstrap'
import { columnsTableStats, optionsTableStats } from './EnumOrder'

const OrdersStats = () => {
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [groupsData, setGroupsData] = useState([])

  useEffect(async () => {
    try {
      setLoading(true)
      const { data } = await axios.get('/orders/getByOrderType')
      if (data.success) {
        if (data.data?.ordersByType) {
          let _data = data.data?.ordersByType.map((o) => {
            return [o.name, o.total, o.category]
          })

          setTableData(_data)
        }
        if (data.data?.ordersByCategory) {
          setGroupsData(data.data?.ordersByCategory)
        }
      }
      setLoading(false)
    } catch (e) {
      console.log('error', e)
    }
  }, [])

  if (tableData) {
    tableData.forEach((item) => {
      if (item.includes('Other')) {
        item[item.indexOf('Other')] = 'Offer'
      }
    })
  }

  const tableHeader = (
    <div className='d-flex justify-content-between'>
      <div style={{ fontSize: '25px' }}>Stats</div>
      <div
        className='d-flex justify-content-between align-items-center'
        sx={{ fontSize: '16px !important', fontWeight: 'lighter !important', flexWrap: 'wrap' }}
      >
        {groupsData.map((group) => (
          <div className='mr-3 ml-3' key={group.id}>
            {group.name === 'Other' ? 'Offer' : group.name}:{' '}
            <Badge pill className='statsGroupOrder' variant='secondary'>
              {group.total}
            </Badge>
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <div className='pt-4'>
      {!loading ? (
        <div>
          <MUIDataTable title={tableHeader} data={tableData} options={optionsTableStats} columns={columnsTableStats} />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default OrdersStats
