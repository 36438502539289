import { Button, Dialog, DialogActions, DialogContent, Grid, TextField, Autocomplete, Checkbox, Typography } from '@mui/material'
import { checkedIcon, office_location, unCheckedIcon } from '../../utils'
import MUIDataTable from 'mui-datatables'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const Under_Management_Users = ({ task_user_id, taskUserName, type, setOpenUnderManagementModal }) => {
  const [userUnderManagement, setUserUnderManagement] = useState([])
  const [makeUsersUnderManagement, setMakeUsersUnderManagement] = useState([])
  const [nonTaskUsers, setNonTaksUsers] = useState([])
  const [params, setParams] = useState({ limit: 5, page: 0, departments: '', locations: '', name: '' })
  const [total, setTotal] = useState(null)
  const [departments, setDepartments] = useState([])
  const [higherUser, setHigherUser] = useState('')
  const types = ['director', 'project_manager', 'manager', 'teamleader']

  useEffect(() => {
    const getUsersUnderManagement = () => {
      axios
        .get(`taskUsers/under_management`, { params: { task_user_id } })
        .then((res) => {
          if (res.data.success) {
            setUserUnderManagement(res.data.data)
          } else {
            toast.error(res.data.message)
          }
        })
        .catch((err) => {
          toast.error('Something went wrong!')
          console.log('error', err)
        })
    }

    const getDepartments = () => {
      axios
        .get(`departments`)
        .then((res) => {
          if (res.data.success) {
            setDepartments(res.data.data)
          } else {
            setDepartments([])
            toast.error(res.data.message)
          }
        })
        .catch((err) => {
          toast.error('Something went wrong!')
          console.log('error', err)
        })
    }

    const getHigherUser = () => {
      axios
        .get(`taskUsers/higherUser`, { params: { task_user_id } })
        .then((res) => {
          if (res.data.success) {
            setHigherUser(res.data.data)
          } else {
            setHigherUser([])
            toast.error(res.data.message)
          }
        })
        .catch((err) => {
          toast.error('Something went wrong!')
          console.log('error', err)
        })
    }

    getUsersUnderManagement()
    getDepartments()
    getHigherUser()
  }, [task_user_id])

  useEffect(() => {
    const getNonTaskUsers = () => {
      axios
        .get(`taskUsers`, { params })
        .then((res) => {
          if (res.data.success) {
            const tableData = res.data.data.map((item) => {
              return [
                item?._id,
                item?.first_name,
                item?.last_name,
                item?.department?.map((item) => item?.name).join(', '),
                item?.office_location,
                <Checkbox
                  size='small'
                  checked={makeUsersUnderManagement.includes(item._id)}
                  onChange={() => handleCheckboxChange(item._id)}
                />
              ]
            })
            setNonTaksUsers(tableData)
            setTotal(res.data.total)
          } else {
            toast.error(res.data.message)
          }
        })
        .catch((err) => {
          toast.error('Something went wrong!')
          console.log('error', err)
        })
    }
    getNonTaskUsers()
  }, [params.page, params.departments, params.locations, params.name, params.limit])

  const displayText = (type, order) => {
    let index = types.indexOf(type)
    switch (types[index + order]) {
      case 'director':
        return 'Direktor'
      case 'project_manager':
        return 'Projekt Menaxher'
      case 'manager':
        return 'Menaxherat'
      case 'teamleader':
        return 'Teamleader'
      default:
        return ''
    }
  }

  const displayTextAdd = (type) => {
    let index = types.indexOf(type)
    switch (types[index + 1]) {
      case 'director':
        return 'Shto direktorë'
      case 'project_manager':
        return 'Shto Projekt Menaxhera'
      case 'manager':
        return 'Shto menaxherat'
      case 'teamleader':
        return 'Shto teamliderat'
      default:
        return ''
    }
  }

  const handleCheckboxChange = (userId) => {
    setMakeUsersUnderManagement(
      (prevSelected) =>
        prevSelected.includes(userId)
          ? prevSelected.filter((id) => id !== userId) // Uncheck
          : [...prevSelected, userId] // Check
    )
  }

  const options = {
    filter: false,
    selectableRows: 'none',
    search: false,
    print: false,
    download: false,
    pagination: true,
    serverSide: true,
    count: total,
    rowsPerPage: 5,
    elevation: 0,
    responsive: 'scroll',
    maxHeight: 'none',
    viewColumns: false,
    rowsPerPageOptions: [5, 10, 50, 100, 200],
    onTableChange: (action, a) => (action === 'changeRowsPerPage' ? (a.page = 0) : null),
    onChangeRowsPerPage: (e) => {
      setParams((prev) => ({ ...prev, limit: e, page: 0 }))
    },
    onChangePage: (e) => {
      setParams((prev) => ({ ...prev, page: e }))
    }
  }

  const columns = [
    { name: '_id', label: '_id', options: { sort: false, display: false } },
    { name: 'first_name', label: 'First Name', options: { sort: false } },
    { name: 'last_name', label: 'Last Name', options: { sort: false } },
    { name: 'department', label: 'Department', options: { sort: false } },
    { name: 'location', label: 'Location', options: { sort: false } },
    {
      name: 'add_task_admin',
      label: displayTextAdd(type),
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <Checkbox
            size='small'
            checked={makeUsersUnderManagement.includes(tableMeta.rowData[0])}
            onChange={() => handleCheckboxChange(tableMeta.rowData[0])}
          />
        )
      }
    }
  ]

  const addUserUnderManagement = () => {
    let index = types.indexOf(type)

    axios
      .patch(`taskUsers/users`, {
        users_under_management: makeUsersUnderManagement,
        task_user_id,
        type_of_new_users: types[index + 1]
      })
      .then((res) => {
        if (res.data.success) {
          setUserUnderManagement(res.data.data)
          toast.success('Users updated successfully!')
        } else {
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message
        toast.error(errorMessage)
        console.log('Request error:', err)
      })
  }

  return (
    <Dialog open maxWidth='md'>
      <DialogContent>
        <Grid container alignItems='center' gap={2} sx={{ mx: 'auto !important', width: '100% !important', flexWrap: {xs:"wrap" , lg:"nowrap"} }}>
          <Grid items xs={12} lg={4} sx={{ border: '1px solid #C4C4C4', borderRadius: '10px', py: 1, px: 2 }}>
            <Typography sx={{ fontSize: '15px', fontWeight: '600', color: '#00000080', whiteSpace: 'nowrap' }}>
              Raporton tek ({displayText(type, -1)}):
            </Typography>
            <p style={{ margin:"0" , padding:"0" }}>
            {higherUser?.user_id?.first_name ? higherUser?.user_id?.first_name + ' ' + higherUser?.user_id?.last_name : 'Nuk ka përdorues'}
            </p>
          </Grid>

          <Grid items xs={12} lg={4} sx={{ border: '1px solid #C4C4C4', borderRadius: '10px', py: 1, px: 2 }}>
            <Typography sx={{ fontSize: '15px', fontWeight: '600', color: '#00000080', whiteSpace: 'nowrap' }}>
              Përdoruesi aktual:
            </Typography>
            <p style={{ margin:"0" , padding:"0" }}>
            {taskUserName}
            </p>
          </Grid>

          <Grid items xs={12} lg={4} sx={{ border: '1px solid #C4C4C4', borderRadius: '10px', py: 1, px: 2 }}>
            <Typography sx={{ fontSize: '15px', fontWeight: '600', color: '#00000080', whiteSpace: 'nowrap' }}>
              Nën menaxhim ({displayText(type, 1)}):
            </Typography>
            {userUnderManagement.length > 0 ? (
              <Grid items sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '1px' ,flexWrap:"wrap" }}>
                {userUnderManagement.map((user) => (
                  <p style={{ margin:"0" , padding:"0" }}>{user.user_id.first_name + ' ' + user.user_id.last_name + ',  '}</p>
                ))}
              </Grid>
            ) : (
              <p style={{ margin:"0" , padding:"0" }}>Nuk ka përdorues</p>
            )}
          </Grid>
        </Grid>

        <Typography variant='h6' mt={2}>
          {displayTextAdd(type)}
        </Typography>
        <Grid
          container
          columnSpacing={2}
          sx={{
            paddingY: '10px',
            fieldset: {
              borderBottom: '1px solid rgba(0,0,0,0.23)'
            }
          }}
        >
          <Grid item xs={4}>
            <TextField
              label='Search User'
              size='small'
              fullWidth
              sx={{ marginRight: '10px' }}
              onChange={(e) => setParams((prev) => ({ ...prev, page: 0, name: e.target.value }))}
            />
          </Grid>
          {departments && (
            <Grid item xs={4}>
              <Autocomplete
                multiple
                sx={{ maxWidth: '100%' }}
                disableCloseOnSelect
                options={departments}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                onChange={(_e, selectedOptions) => {
                  const selectedIds = selectedOptions.map((option) => option._id)
                  setParams((prev) => ({ ...prev, departments: selectedIds }))
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option._id}>
                    <Checkbox icon={unCheckedIcon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => <TextField {...params} label='Departments' placeholder='Departments' size='small' />}
              />
            </Grid>
          )}
          {office_location && (
            <Grid item xs={4}>
              <Autocomplete
                multiple
                sx={{ maxWidth: '100%' }}
                disableCloseOnSelect
                options={office_location}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(_e, selectedOptions) => {
                  const selectedIds = selectedOptions.map((option) => option.value)
                  setParams((prev) => ({ ...prev, locations: selectedIds }))
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option._id}>
                    <Checkbox icon={unCheckedIcon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => <TextField {...params} label='Location' placeholder='Location' size='small' />}
              />
            </Grid>
          )}
        </Grid>

        <MUIDataTable columns={columns} options={options} data={nonTaskUsers} />
      </DialogContent>
      <DialogActions sx={{ marginTop: '10px' }}>
        <Button
          variant='contained'
          color='success'
          onClick={() => {
            addUserUnderManagement()
            setOpenUnderManagementModal(false)
          }}
        >
          Ruaj
        </Button>
        <Button variant='contained' color='error' onClick={() => setOpenUnderManagementModal(false)}>
          Anulo
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Under_Management_Users
