import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import CancelIcon from '@material-ui/icons/Cancel'
import {
  Avatar,
  AvatarGroup,
  Box,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
  linearProgressClasses,
  styled
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { taskUsersReport } from '../../../../API/endpoints'
import CardPaginate from '../../../Task_Report_Card/Card_Paginate'
import Tasks_Report_Card_Toolbar from '../Toolbar/Toolbar_Card'
import { mainBoxStyles } from '../../../Manage_Tasks_Tab/components/utils'

const UserReportCard = () => {
  const [params, setParams] = useState({
    limit: 10,
    page: 0,
    name: null,
    date: new Date(),
    type: null
  })

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#4CAF50' : '#308fe8'
    }
  }))

  const userReportData = useQuery({
    queryKey: ['taskUsersReport', params.limit, params.page, params.name, params.date, params.type],
    queryFn: () => taskUsersReport(params),
    staleTime: 50000
  })

  if (userReportData.isError) return <pre>{JSON.stringify(userReportData.error)}</pre>

  const maxVisible = 4 // Maximum number of avatars to show
  return (
    <Card sx={{ ...mainBoxStyles, marginTop: 8 }}>
      <Tasks_Report_Card_Toolbar params={params} setParams={setParams} user={true} title='Raporti i Përdoruesve' />

      {userReportData.isLoading ? (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={4}>
          {userReportData.data.data.map((taskUser, index) => {
            const tasks = taskUser.total
            const visibletaks = tasks?.slice(0, maxVisible)
            const overflowCount = tasks?.length - maxVisible
            const totalTasks = taskUser?.total
            const tasksCompleted = taskUser?.completed
            const percentage = (tasksCompleted?.length / totalTasks?.length) * 100
            return (
              <Grid item key={index} xs={12} sm={12} md={6} lg={4} xl={3}>
                <Link
                  style={{ color: 'black', textDecoration: 'none' }}
                  to={`/tasks-reports/user/${taskUser?.userData?._id}?date=${params.date}`}
                >
                  <Card sx={{ border: 0, borderColor: 'fff', borderStyle: 'fff' }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        <Tooltip
                          key={taskUser?.userData?._id}
                          title={`${taskUser?.userData?.first_name} ${taskUser?.userData?.last_name}`}
                          placement='bottom'
                        >
                          <Typography variant='h5' component='h2'>
                            {`${taskUser?.userData?.first_name} ${taskUser?.userData?.last_name}`}
                          </Typography>
                        </Tooltip>
                      </Box>

                      <Tooltip key={taskUser?.userData?.project} title={taskUser?.userData?.project?.name} placement='bottom'>
                        <Chip
                          key={taskUser?.userData?.project}
                          variant='outlined'
                          style={{ color: '#707173', fontWeight: 'bold', fontSize: '12px', marginRight: '10px' }}
                          size='small'
                          label={taskUser?.userData?.project?.name}
                        />
                      </Tooltip>
                      <Tooltip key={taskUser?.userData?.department[0]} title={taskUser?.userData?.department[0]?.name} placement='bottom'>
                        <Chip
                          key={taskUser?.userData?.department[0]}
                          variant='outlined'
                          style={{ color: '#707173', fontWeight: 'bold', fontSize: '12px', marginRight: '10px' }}
                          size='small'
                          label={taskUser?.userData?.department[0]?.name}
                        />
                      </Tooltip>
                    </CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', paddingX: '16px' }}>
                      <Box sx={{ width: '100%', mr: 1 }}>
                        <BorderLinearProgress variant='determinate' value={percentage} />
                      </Box>
                      <Box>
                        <Typography variant='body2' color='text.secondary'>{`${Math.round(percentage)}%`}</Typography>
                      </Box>
                    </Box>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography variant='body2' component='p' gutterBottom>
                          Taskat e deleguara:
                        </Typography>
                        <Box sx={{display:"flex", justifyContent:"flex-start", gap:"5px", alignItems:"center"}}>
                          {visibletaks?.map((task, index) => {
                            const firstWord = task.name?.split(' ')[0] || ''
                            return (
                              <Tooltip key={index} title={task.name} placement='bottom'>
                                <Chip key={index} label={firstWord + '...'} variant='outlined' sx={{ height: '30px' }} />
                              </Tooltip>
                            )
                          })}
                          {overflowCount > 0 && (
                            <Tooltip
                              title={visibletaks.slice(maxVisible).map((task, index) => (
                                <div key={index}>{`${task.name}`}</div>
                              ))}
                              placement='bottom'
                            >
                              <Chip sx={{ height: 25 }}>+{overflowCount}</Chip>
                            </Tooltip>
                          )}
                        </Box>
                      </Box>
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <div style={{ textAlign: 'center' }}>
                          <Tooltip
                            title={taskUser?.completed?.map((task, index) => (
                              <div key={index}>{`${task?.name}`}</div>
                            ))}
                            placement='bottom'
                          >
                            <AssignmentTurnedInIcon style={{ fill: '#4CAF50' }} />
                            <Typography variant='body2' component='p' color='textSecondary'>
                              {taskUser.completed.length || 0} Kompletuara
                            </Typography>
                          </Tooltip>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                          <Tooltip
                            title={taskUser.uncompleted.map((task, index) => (
                              <div key={index}>{`${task.name}`}</div>
                            ))}
                            placement='bottom'
                          >
                            <CancelIcon style={{ fill: '#FF010199' }} />
                            <Typography variant='body2' component='p' color='textSecondary'>
                              {taskUser.uncompleted.length || 0} Pa kompletuara
                            </Typography>
                          </Tooltip>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            )
          })}
        </Grid>
      )}
      <CardPaginate params={params} setParams={setParams} total={userReportData?.data?.total} />
    </Card>
  )
}

export default UserReportCard
