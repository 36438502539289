import { Chip, Grid, StepLabel, Tooltip, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import Stepper from '@mui/material/Stepper'
import moment from 'moment'
import { RenderChips } from '../../utils'

const My_Today_Tasks_Timeline = ({ tasks, setIsCompletedTask, setTaskId }) => {
  const firstDividedBy3 = Math.floor(tasks.length / 3)
  const secondDividedBy2 = (tasks.length - firstDividedBy3) / 2
  const mathCeilSecondDividedBy2 = Math.ceil(secondDividedBy2)
  const mathFloor = Math.floor(secondDividedBy2)

  const firstArray = tasks.slice(0, mathCeilSecondDividedBy2)
  const secondArray = tasks.slice(mathCeilSecondDividedBy2, mathCeilSecondDividedBy2 + mathFloor)
  const thirdArray = tasks.slice(mathCeilSecondDividedBy2 + mathFloor)

  return (
    <Grid container spacing={2}>
      {[firstArray, secondArray, thirdArray].map((tasks, groupIndex) => (
        <Grid item sm={12} md={12} lg={6} xl={4}>
          <Stepper key={groupIndex} orientation='vertical'>
            {tasks.map((step, index) => (
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                sx={{
                  '> div': {
                    '&:first-child': {
                      '> span': {
                        '&:first-child': {
                          display: 'none'
                        }
                      }
                    }
                  }
                }}
              >
                <Step className='stepperTask' key={index} active sx={{ mt: 2 }}>
                  <StepLabel
                    StepIconProps={{
                      ...(step.completed && { style: { color: 'green' } })
                    }}
                    icon={step.index}
                  >
                    <Typography variant='body1' fontWeight='bold'>
                      {' '}
                      {step.name}
                    </Typography>
                  </StepLabel>
                  <StepContent
                    sx={{
                      height: '220px',
                      position: 'relative',
                      background: '#fff',
                      borderRadius: '4px',
                      padding: '20px 20px 220px 20px',
                      borderLeft: 'none',
                      marginLeft: '0',
                      boxShadow: '4px 4px 4px #f3f3f3'
                    }}
                  >
                    <Tooltip key={step.index} title={step.description} placement='bottom'>
                      <Typography variant='body2' fontStyle='italic' mb={1}>
                        {step.description.length <= 150 ? step.description : `${step.description.slice(0, 150)}...`}
                      </Typography>
                    </Tooltip>
                    {step.due_date && (
                      <Typography variant='body2' fontWeight='bold' mb={1}>
                        Due Date:&nbsp;{' '}
                        <Chip
                          variant='outlined'
                          size='small'
                          label={`${step.due_date ? moment(step.due_date).format('DD/MM/YYYY') : ''}`}
                        />
                      </Typography>
                    )}
                    <Typography variant='body2' fontWeight='bold'>
                      Due Time: &nbsp;
                      <Chip size='small' label={`${step?.due_time?.hours}:${step?.due_time?.minutes}`} variant='outlined' />
                    </Typography>
                    {step.repetition.length > 0 && (
                      <Grid container gap={1} mt={2}>
                        <RenderChips activeDay={step?.repetition} />
                      </Grid>
                    )}
                    <Button
                      color={step.completed ? 'success' : 'primary'}
                      variant='contained'
                      size='small'
                      sx={{ mt: 2, position: 'absolute', bottom: '20px', right: '20px' }}
                      onClick={() => {
                        setIsCompletedTask(step.completed)
                        setTaskId(step._id)
                      }}
                    >
                      {step.completed ? 'Shiko' : 'Kompleto'}
                    </Button>
                  </StepContent>
                </Step>
              </Grid>
            ))}
          </Stepper>
        </Grid>
      ))}
    </Grid>
  )
}
export default My_Today_Tasks_Timeline
