import { Box, Button, Card, Divider, Grid, TextField, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import BallotIcon from '@material-ui/icons/Ballot'
import { Bar, BarChart, Cell, XAxis, YAxis } from 'recharts'
import {
  CustomChip,
  RenderChips,
  boxStyling,
  enum_assignment_type,
  enum_task_type,
  flexTypography,
  titleBoxStyling,
  titleChipStyling
} from '../../utils'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Locale from 'date-fns/locale/en-GB'
import {
  AccessTime,
  CalendarToday,
  Comment,
  LocationCity,
  PersonAddOutlined,
  Repeat,
  RepeatOne,
  SupervisedUserCircleRounded,
  TextsmsOutlined,
  ToggleOffOutlined,
  ToggleOnOutlined
} from '@material-ui/icons'

const Task_Details = () => {
  const params = useParams()
  const search = useLocation().search

  const paramsSearch = new URLSearchParams(search)
  const setDates = paramsSearch.get('date')
  const id = params.id
  const [taskData, setTaskData] = useState(null)
  const [completed, setCompleted] = useState([])
  const [unCompleted, setUnCompleted] = useState([])
  // const [date, setDate] = useState(setDates)
  const [date, setDate] = useState(setDates || new Date())
  useEffect(() => {
    axios
      .get(`taskReport/task/${id}`, { params: { date } })
      .then((res) => {
        if (res.data.success) {
          setTaskData(res.data.data.taskData)
          setCompleted(res.data.data.completed)
          setUnCompleted(res.data.data.uncompleted)
        } else {
          setTaskData()
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }, [date])

  const chartData = [
    {
      name: 'Të Deleguara',
      Count: taskData?.assigned_users?.length,
      color: '#5E167590'
    },
    {
      name: 'Të Kompletuara',
      Count: completed?.length,
      color: '#FFD23F90'
    },
    {
      name: 'Të pa kompletuara',
      Count: unCompleted?.length,
      color: '#EE426690'
    }
  ]

  return (
    <Box sx={{ padding: '20px 10px' }}>
      <Box sx={{ maxWidth: 'fit-content', marginBottom: '20px', marginLeft: 'auto' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
          <DatePicker
            label='Date'
            inputFormat='dd/MM/yyyy'
            value={date}
            backgroundColor='white'
            minDate={taskData?.created_at}
            maxDate={new Date()}
            onChange={(e) => setDate(e)}
            renderInput={(params) => <TextField {...params} sx={{ width: '150px', backgroundColor: '#fff' }} size='small' />}
            componentsProps={{
              actionBar: {
                actions: ['clear']
              }
            }}
          />
        </LocalizationProvider>
      </Box>
      {taskData ? (
        <>
          <Grid container spacing={1} px={2} mb={2}>
            <Grid item xs={12} md={12} xl={12}>
              <Box display='flex' justifyContent='space-between' alignItems='center' pt={2}>
                <Typography variant='h6' fontWeight='bold' textTransform='capitalize' mb={1}>
                  {taskData?.name}
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item container xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', gap: '20px', flexWrap: 'wrap', mb: 2 }}>
                  <Box sx={boxStyling}>
                    <Box>
                      <Typography className='header' fontWeight='bold'>
                        Përshkrimi:
                      </Typography>
                      <Tooltip title={taskData?.description} arrow placement='bottom'>
                        <Typography className='header'>{taskData?.description.slice(0, 60)}...</Typography>
                      </Tooltip>
                    </Box>
                    <TextsmsOutlined fontSize='large' />
                  </Box>
                  <Box sx={boxStyling}>
                    <Box>
                      <Typography className='header' fontWeight='bold'>
                        Afati Kohor:
                      </Typography>
                      <Typography className='header'>
                        {taskData?.due_time?.hours}:{taskData?.due_time?.minutes}
                      </Typography>
                    </Box>
                    <AccessTime fontSize='large' />
                  </Box>
                  <Box sx={boxStyling}>
                    <Box>
                      <Typography className='header' fontWeight='bold'>
                        Tipi/Data:
                      </Typography>
                      <Typography className='header'>
                        {taskData.due_date ? moment(taskData?.due_date).format('DD/MM/YYYY') : 'E Përsëritshme'}
                      </Typography>
                    </Box>

                    <CalendarToday fontSize='large' />
                  </Box>
                  <Box sx={boxStyling}>
                    <Box>
                      <Typography className='header' fontWeight='bold'>
                        Deleguar për:
                      </Typography>
                      <Typography className='header'>
                        {taskData?.type === enum_assignment_type['all_users'] ? 'Të gjithë përdoruesit' : 'Përdoruesit e perzgjedhur'}
                      </Typography>
                    </Box>
                    <SupervisedUserCircleRounded fontSize='large' />
                  </Box>

                  <Box sx={boxStyling}>
                    <Box>
                      <Typography className='header' fontWeight='bold'>
                        Tipi:
                      </Typography>
                      <Typography className='header'>
                        {taskData?.task_type === enum_task_type['single'] ? 'Single' : 'Repetitive'}
                      </Typography>
                    </Box>
                    {taskData?.task_type === enum_task_type['single'] ? <RepeatOne fontSize='large' /> : <Repeat fontSize='large' />}
                  </Box>

                  <Box sx={boxStyling}>
                    <Box>
                      <Typography className='header' fontWeight='bold'>
                        Është Aktive:
                      </Typography>
                      <Typography className='header'>{taskData?.is_active ? 'Aktive' : 'Jo Aktive'}</Typography>
                    </Box>
                    {taskData?.is_active ? (
                      <ToggleOnOutlined fontSize='large' style={{ fill: 'green' }} />
                    ) : (
                      <ToggleOffOutlined fontSize='large' style={{ fill: 'red' }} />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} px={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Card sx={{ p: 2, minHeight: '270px', boxShadow: '0' }}>
                {taskData.repetition.length > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: '10px' }}>
                    <Typography variant='body1' fontWeight='bold' sx={{ marginBottom: '10px' }}>
                      Ditët e përsëritura:
                    </Typography>
                    <Grid
                      container
                      columnSpacing={1}
                      sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '20px', pl: 1 }}
                    >
                      <CustomChip activeDay={taskData?.repetition} />
                    </Grid>
                  </Box>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <Card sx={{ p: 2, minHeight: '270px', boxShadow: '0' }}>
                <Typography variant='body1' fontWeight='bold' sx={{ marginBottom: '10px' }}>
                  Detajet e raportit të Taskës
                </Typography>
                <BarChart width={630} height={200} data={chartData}>
                  <Bar dataKey='Count' label={{ fill: 'white' }}>
                    {chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry?.color} />
                    ))}
                  </Bar>
                  <Tooltip />
                  <XAxis dataKey='name' />
                  <YAxis />
                </BarChart>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' fontWeight='bold' sx={{ marginBottom: '10px' }}>
                Të pa përfunduara nga:
              </Typography>
              <Grid container spacing={2}>
                {unCompleted?.map((item) => (
                  <Grid item key={item?._id} xs={6} md={4} lg={3} xl={2}>
                    <Card
                      sx={{
                        p: 2,
                        boxShadow: '0',
                        '> a': {
                          textDecoration: 'none',
                          transition: 'all .4s ease'
                        }
                      }}
                    >
                      <Link to={`/editUsers/${item?.user_id}`}>
                        <Typography variant='h5' sx={titleBoxStyling}>
                          {item?.first_name} {item?.last_name}
                        </Typography>
                        <Typography variant='h6' sx={titleChipStyling}>
                          {item?.project?.name}
                        </Typography>
                        <Typography variant='h6' sx={titleChipStyling}>
                          {item?.department?.[0]?.name}
                        </Typography>
                      </Link>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant='body1' fontWeight='bold' mt={2}>
                Detajet e Taskave të kompletuara
              </Typography>
            </Grid>
            <Grid item container>
              {completed?.map((item) => (
                <Grid item key={item?._id} xs={6} md={4} lg={3} xl={2}>
                  <Card
                    sx={{
                      p: 2,
                      boxShadow: '0',
                      '> a': {
                        textDecoration: 'none',
                        transition: 'all .4s ease'
                      }
                    }}
                  >
                    <Link to={`/editUsers/${item?.created_by?.user_id}`}>
                      <Typography sx={titleBoxStyling}>
                        {item?.created_by?.first_name} {item?.created_by?.last_name}
                      </Typography>
                      <Typography sx={titleChipStyling}>{item?.created_by?.project?.name}</Typography>
                      <Typography sx={titleChipStyling}>{item?.created_by?.department[0]?.name}</Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          border: '1px solid #ccc',
                          width: '90px',
                          borderRadius: '12px',
                          padding: '3px 7px 0 7px',
                          mb: 1,
                          gap: '8px'
                        }}
                      >
                        {<AccessTime fontSize='small' />}
                        <Typography>{moment(item?.created_date).format('HH:MM')}</Typography>
                      </Box>
                      <Box sx={flexTypography}>
                        {<LocationCity />}
                        <Typography> {item?.location}</Typography>
                      </Box>
                      <Box sx={flexTypography}>
                        {<BallotIcon />}
                        <Typography>{item?.floor}</Typography>
                      </Box>
                      <Box sx={flexTypography} fontStyle='italic'>
                        {<Comment />}
                        <Typography> {item?.comment}</Typography>
                      </Box>
                    </Link>
                  </Card>
                </Grid>
              ))}
              <Box
                sx={{
                  minWidth: '800px',
                  width: '100%'
                }}
              >
                <Box
                  sx={{
                    maxHeight: '400px',
                    overflowY: 'auto'
                  }}
                ></Box>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography>Nuk ka te dhena per kete date, zgjidh nje date tjeter!</Typography>
      )}
    </Box>
  )
}

export default Task_Details
