import React from 'react'
import TaskCard from '../../../Task_Report_Card/Card'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { tasks } from '../../../../API/endpoints'
import Manage_Tasks_Card_Toolbar from './Manage_Tasks_Card_Toolbar'
import CardPaginate from '../../../Task_Report_Card/Card_Paginate'
import { CircularProgress } from '@mui/material'

const Active_Card = () => {
  const [params, setParams] = useState({
    limit: 10,
    page: 0,
    name: null,
    due_time: null,
    due_date: null,
    task_type: null,
    assignment_type: null,
    is_active: true
  })

  const activeTasks = useQuery({
    queryKey: [
      'activeTasks',
      params.limit,
      params.page,
      params.name,
      params.due_date,
      params.due_time,
      params.task_type,
      params.assignment_type,
      params.is_active
    ],
    queryFn: () => tasks(params),
    staleTime: 50000
  })

  if (activeTasks.isError) return <pre>{JSON.stringify(activeTasks.error)}</pre>
  return (
    <>
      <Manage_Tasks_Card_Toolbar params={params} isActive={true} setParams={setParams} title='Taskat Aktive' />

      {activeTasks.isLoading ? <CircularProgress /> : <TaskCard tasksReportData={activeTasks} dep={true} />}
      <CardPaginate params={params} setParams={setParams} total={activeTasks?.data?.total} />
    </>
  )
}

export default Active_Card
