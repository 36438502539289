import { Typography, Grid, Box, Divider } from '@mui/material'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import EventIcon from '@material-ui/icons/Event'
import AlarmOnIcon from '@material-ui/icons/AlarmOn'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import BallotIcon from '@material-ui/icons/Ballot'
import moment from 'moment'

const ShowDetails = ({ task, color, type, hide = false }) => {
  const data = task.task_id ? task.task_id : task

  return (
    <Box sx={{}}>
      <Typography>
        <span style={{ fontWeight: 'bold' }}>Emri i taskut: </span> {data?.name}
      </Typography>
      <Box>
        <Typography variant='body2'>
          <span style={{ fontWeight: 'bold' }}>Përshkrimi: </span>
          {data?.description}
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ display: 'flext', justifyContent: 'space-between', marginBottom: '5px', marginTop: '1px' }}>
        <Grid item sm={12} md={12} lg={6} xl={6}>
          <Typography>
            <QueryBuilderIcon sx={{ marginRight: 2.5 }} /> &nbsp; {moment(data?.due_time.date_format).format('HH:mm')}
          </Typography>
        </Grid>
        {data.due_date && (
          <Grid item sm={12} md={12} lg={6} xl={6}>
            <Typography>
              <EventIcon sx={{ marginRight: 2.5 }} /> &nbsp; {moment(data?.due_date).format('DD/MM/YYYY')}
            </Typography>
          </Grid>
        )}

        {type === 'completed' && (
          <Grid item sm={12} md={12} lg={6} xl={6}>
            <Typography>
              <AlarmOnIcon sx={{ marginRight: 2.5 }} /> &nbsp; {moment(task?.created_date).format('HH:mm')}
            </Typography>
          </Grid>
        )}
        {type === 'completed' && (
          <Grid item sm={12} md={12} lg={6} xl={6}>
            <Typography>
              <LocationOnIcon sx={{ marginRight: 2.5 }} /> &nbsp; {task?.location}
            </Typography>
          </Grid>
        )}
        {type === 'completed' && (
          <Grid item sm={12} md={12} lg={6} xl={6}>
            <Typography>
              <BallotIcon sx={{ marginRight: 2.5 }} /> &nbsp; {task?.floor}
            </Typography>
          </Grid>
        )}
        {type === 'completed' && (
          <Grid item sm={12} md={12} lg={12} xl={12}>
            <Typography> Komenti: </Typography>
            <Typography variant='body2' sx={{ paddingX: 2.5 }}>
              {task?.comment}
            </Typography>
          </Grid>
        )}
      </Grid>
      {!hide && <Divider sx={{ margin: '10px 0 20px 0', borderColor: `${color} !important` }} />}
    </Box>
  )
}

export default ShowDetails
