import { Box, Tabs, Tab, Button } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import Manage_Tasks_Tab from '../components/Manage_Tasks_Tab/Manage_Tasks_Tab'
import Tasks_Report_Tab from './../components/Tasks_Report_Tab/Tasks_Report_Tab'
import { useState } from 'react'
const Manage_Tasks = () => {
  const history = useHistory()
  const location = useLocation()
  const search = useLocation().search
  const paramsSearch = new URLSearchParams(search)
  const currentIndex = paramsSearch.get('index') || 0
  const [value, setValue] = useState(0 | parseInt(paramsSearch.get('index')))

  const setTabIndexOnQuery = (event, index) => {
    setValue(index)
    const params = new URLSearchParams({ index: index })
    history.replace({ pathname: location.pathname, search: params.toString() })
  }
  return (
    <Box sx={{ padding: '20px' }}>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs value={value} onChange={setTabIndexOnQuery} centered fullWidth>
          <Tab label='Raporti i Taskave' />
          <div style={{ width: '500px' }} />
          <Tab label='Menaxhimi i Taskave' />
        </Tabs>
      </Box>

      {currentIndex == 0 && <Tasks_Report_Tab />}
      {currentIndex == 2 && <Manage_Tasks_Tab />}
    </Box>
  )
}

export default Manage_Tasks
