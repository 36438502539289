import ExpandMore from '@material-ui/icons/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { useState } from 'react'
import Tasks_Users from './../Tasks_Users/Tasks_Users'
import Manage_Task_Card from './components/Manage_Task_Card/Manage_Task_Card'
import jwt_decode from 'jwt-decode'

const accordions = [
  {
    title: 'Directors',
    component: <Tasks_Users type='director' />
  },
  {
    title: 'Project Managers',
    component: <Tasks_Users type='project_manager' />
  },
  {
    title: 'Managers',
    component: <Tasks_Users type='manager' />
  }
]

const Manage_Tasks_Tab = () => {
  const [expandedAccordion, setExpandedAccordion] = useState(null)
  const token = localStorage.getItem('token')
  const decodedToken = jwt_decode(token)
  const { user_level } = decodedToken

  return (
    <>
      {user_level === 1 && (
        <Box sx={{ marginY: '20px' }}>
          {accordions?.map(({ title, component }, index) => (
            <Accordion
              key={index}
              expanded={expandedAccordion === title}
              onChange={(_e, isExpanded) => setExpandedAccordion(isExpanded ? title : null)}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>{title}</Typography>
              </AccordionSummary>
              <AccordionDetails>{expandedAccordion === title && component}</AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}

      <Manage_Task_Card />
    </>
  )
}

export default Manage_Tasks_Tab
