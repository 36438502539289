import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  ListItemText,
  TextField,
  CircularProgress
} from '@mui/material'
import React, { useState } from 'react'
import { capitalizeFirstLetter, floors, office_location } from '../../utils'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { completeTask, taskDetail } from '../../API/endpoints'

const Complete_Task_Modal = ({ task_id, onCloseModal }) => {
  const [newReportData, setNewReportData] = useState([
    {
      floor: '',
      location: '',
      comment: ''
    }
  ])
  const queryClient = useQueryClient()

  const completeTaskMutation = useMutation({
    mutationFn: completeTask,
    onSuccess: (data) => {
      queryClient.setQueryData(['myTodayTask'], data)
      queryClient.invalidateQueries(['myTodayTask'], data, { exact: true })
      onCloseModal()
    }
  })

  const taskDetaildata = useQuery({
    queryKey: ['taskDetail', task_id],
    queryFn: () => taskDetail(task_id)
  })

  const onSubmit = async () => {
    if (!newReportData?.floor || !newReportData?.location) {
      return toast.error('Kati dhe Lokacioni jane te nevojshem')
    }

    completeTaskMutation.mutate({ newReportData, task_id })
  }
  if (taskDetaildata.isError) return <pre>{JSON.stringify(taskDetaildata.error)}</pre>

  return (
    <Dialog open fullWidth sx={{ fieldset: { border: '1px solid' } }}>
      <DialogTitle>
        <Typography variant='h6' fontWeight='bold'>
          Kompleto Taskën
        </Typography>
      </DialogTitle>
      {taskDetaildata.isLoading ? (
        <CircularProgress />
      ) : (
        <DialogContent>
          <Box
            sx={{
              'div:nth-child(2)': {
                'p:nth-child(2)': {
                  fontStyle: 'italic'
                }
              }
            }}
          >
            {taskDetaildata.data.map(({ title, value }) => (
              <Box
                key={title}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column'
                }}
              >
                <Typography variant='body1' fontWeight='bold' mt={1}>
                  {title}:
                </Typography>
                <Typography variant='body1'>{value}</Typography>
              </Box>
            ))}
          </Box>
          <Box sx={{ marginTop: '20px' }}>
            <Box>
              <FormControl sx={{ width: { xs: '100%', md: '47.6%' }, textAlign: 'left', mb: { xs: 2, md: 0 } }}>
                <InputLabel size='small' id='task_report_floor_label'>
                  Floor
                </InputLabel>
                <Select
                  size='small'
                  labelId='task_report_floor_label'
                  value={newReportData?.floor ?? ''}
                  onChange={(e) => setNewReportData((prev) => ({ ...prev, floor: e.target.value }))}
                  input={<OutlinedInput label='Floor' size='small' />}
                >
                  {floors.map(({ value, name }) => (
                    <MenuItem key={value} value={value}>
                      <ListItemText primary={capitalizeFirstLetter(name)} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: { xs: '100%', md: '47.6%' }, textAlign: 'left', marginLeft: { xs: 0, md: '25px' } }}>
                <InputLabel size='small' id='task_report_location_label'>
                  Location
                </InputLabel>
                <Select
                  size='small'
                  labelId='task_report_location_label'
                  value={newReportData?.location ?? ''}
                  onChange={(e) => setNewReportData((prev) => ({ ...prev, location: e.target.value }))}
                  input={<OutlinedInput label='Location' size='small' />}
                >
                  {office_location.map(({ value, name }) => (
                    <MenuItem key={value} value={value}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <TextField
              value={newReportData?.comment}
              onChange={(e) => setNewReportData((prev) => ({ ...prev, comment: e.target.value }))}
              label='Comment'
              placeholder='Comment'
              variant='outlined'
              size='small'
              fullWidth
              sx={{ marginTop: '15px' }}
            />
          </Box>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onCloseModal} size='small' variant='contained' color='error'>
          Cancel
        </Button>
        <Button onClick={onSubmit} size='small' variant='contained'>
          Complete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Complete_Task_Modal