import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const Task_Report_Modal = ({ task_id, onCloseModal }) => {
  const [taskReportDetails, setTaskReportDetails] = useState([])

  useEffect(() => {
    axios
      .get(`taskReport/${task_id}`)
      .then((res) => {
        if (res.data.success) {
          const data = res.data.data
          setTaskReportDetails([
            {
              title: 'Name',
              value: data?.task_id?.name
            },
            {
              title: 'Description',
              value: data?.task_id?.description
            },
            {
              title: 'Due Time',
              value: `${data?.task_id?.due_time?.hours}:${data?.task_id?.due_time?.minutes}`
            },
            {
              title: 'Completed Time',
              value: data?.created_date ? moment(data?.created_date).format('HH:MM') : ''
            },
            {
              title: 'Floor',
              value: data?.floor
            },
            {
              title: 'Location',
              value: data?.location
            },
            {
              title: 'Comment',
              value: data?.comment
            }
          ])
        } else {
          setTaskReportDetails([])
          toast.error(res.data.message)
        }
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        console.log('error', err)
      })
  }, [task_id])

  return (
    <Dialog fullWidth open sx={{ fieldset: { border: '1px solid' } }}>
      <DialogTitle>
        <Typography variant='h6' fontWeight='bold' textAlign='center'>
          Detajet e Taskut të kompletuar
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          {taskReportDetails.map(({ title, value }) => (
            <Box
              key={title}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderBottom: '1px solid #ccc',
                mb: '10px',
                ' p': {
                  minWidth: '200px',
                  pl: '5px'
                }
              }}
            >
              <Typography variant='h6' fontWeight='bold' sx={{ width: '200px', height: '100%' }}>
                {title}:
              </Typography>
              <Typography sx={{ width: '300px', height: '100%' }}>{value}</Typography>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal} size='small' variant='contained' color='error'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Task_Report_Modal
