import axios from 'axios'
import { toast } from 'react-toastify'

// taskReport
export const taskReport = async (params) => {
  const res = await axios.get('taskReport/tasks', { params })
  if (res?.data?.success) {
    return res.data
  } else {
    throw new Error(res.data.message || 'Failed to fetch task report')
  }
}

// createTask
export const createTask = async ({ newTaskData }) => {
  const res = await axios.post('task', newTaskData)
  if (res?.data?.success) {
    toast.success('Tasku u krijua me sukses!')
    return res.data
  } else {
    throw new Error(res.data.message || 'Task creation failed')
  }
}

// taskUsersReport
export const taskUsersReport = async (params) => {
  const res = await axios.get('taskReport/users', { params })
  if (res?.data?.success) {
    return res.data
  } else {
    throw new Error(res.data.message || 'Failed to fetch task users report')
  }
}

// tasks
export const tasks = async (params) => {
  const res = await axios.get('task', { params })
  if (res?.data?.success) {
    return res.data
  } else {
    throw new Error(res.data.message || 'Failed to fetch tasks')
  }
}

// myTodayTasks
export const myTodayTasks = async () => {
  const res = await axios.get('task/my_tasks_for_today')
  if (res?.data?.success) {
    return res?.data?.data
  } else {
    throw new Error(res.data.message || "Failed to fetch today's tasks")
  }
}

// taskDetail
export const taskDetail = async (task_id) => {
  const res = await axios.get(`task/${task_id}`)
  if (res?.data?.success) {
    const data = res.data.data
    return [
      { title: 'Name', value: data?.name },
      { title: 'Description', value: data?.description },
      { title: 'Due Time', value: `${data?.due_time?.hours}:${data?.due_time?.minutes}` }
    ]
  } else {
    throw new Error(res.data.message || 'Failed to fetch task details')
  }
}

// completeTask
export const completeTask = async ({ newReportData, task_id }) => {
  const res = await axios.post(`taskReport/${task_id}`, newReportData)
  if (res?.data?.success) {
    toast.success('Tasku u kompletua me sukses!')
    return res.data
  } else {
    throw new Error(res.data.message || 'Failed to complete task')
  }
}
