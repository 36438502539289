import { Add } from '@material-ui/icons'
import { Box, Button, Card } from '@mui/material'
import { useState } from 'react'
import { mainBoxStyles } from '../utils'
import New_Task_Modal from './../../../New_Task_Modal/New_Task_Modal'
import Active_Card from './Active_Card'
import InActiveCard from './InActive_Card'

const Manage_Task_Card = () => {
  const [addNewModal, setAddNewModal] = useState(false)

  return (
    <>
      <Card sx={mainBoxStyles}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button startIcon={<Add />} variant='outlined' onClick={() => setAddNewModal(true)} sx={{ mt: 0, mb: 2 }}>
            Shto
          </Button>
        </Box>
        {addNewModal && (
          <New_Task_Modal
            onCloseModal={() => {
              setAddNewModal(false)
            }}
          />
        )}

        <Active_Card />
      </Card>
      <Card sx={mainBoxStyles}>
        <InActiveCard />
      </Card>
    </>
  )
}

export default Manage_Task_Card
